/* eslint-disable*/
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes, useLocation } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@material-ui/core/styles';
import GlobalStyles from 'components/GlobalStyles';
import theme from 'theme/index';
import routes from 'routes.js';
import { setError } from 'feactures/errorSlice';
import StickyButton from 'components/atoms/button/StickyButton';
import { currentchannel } from 'feactures/channel/channelSlice';

import 'moment/locale/ja';
import { userLoggedIn, userinfo } from 'feactures/user/userSlice';
import { groupListLength } from 'feactures/monitoring/groupSlice';
import NoticeDialog from 'components/atoms/notice/NodiceDialog';

// export const apiUrl = 'http://52.197.202.214:3000/api/'; //boxwb1
// export const apiUrl = 'http://18.183.158.221:3000/api/'; //web2
// export const apiUrl = 'https://www.oxcim.jp/api/'; //本番
export const apiUrl = 'api/'; //本番
// export const apiUrl = 'http://127.0.0.1:5000/api/'; //ローカル

const App = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(userLoggedIn);
  const channel = useSelector(currentchannel);
  const userInfo = useSelector(userinfo);
  const groupLength = useSelector(groupListLength);

  //ユーザーの各モールで有償プラン利用中のときのみ、premiumStatusをtrueにする←マーケットビューにて使用
  let premiumStatus = false;
  if (
    (channel == 1 &&
      userInfo.plan.amazon.plan != 'トライアルプラン' &&
      userInfo.plan.amazon.status == 1) ||
    (channel == 1 && userInfo.trial.Amazon.trial)
  ) {
    premiumStatus = true;
  } else if (
    (channel == 2 &&
      userInfo.plan.rakuten.plan != 'トライアルプラン' &&
      userInfo.plan.rakuten.status == 1) ||
    (channel == 2 &&
      userInfo.trial.楽天.trial &&
      userInfo.plan.rakuten.plan_id == 4 &&
      userInfo.rakutenStatus >= 7)
  ) {
    premiumStatus = true;
  } else if (
    (channel == 3 &&
      userInfo.plan.yahoo.plan != 'トライアルプラン' &&
      userInfo.plan.yahoo.status == 1) ||
    (channel == 3 && userInfo.trial.yahoo.trial)
  ) {
    premiumStatus = true;
  }

  let preTrialStatus = false;
  if (channel == 2 && userInfo.trial.楽天.trial) {
    preTrialStatus = true;
  }

  const routing = useRoutes(
    routes(isLoggedIn, premiumStatus, preTrialStatus, groupLength, channel)
  );
  const location = useLocation();

  // 画面が遷移したらエラーメッセージを初期化
  useEffect(() => {
    dispatch(setError({ state: null, data: null }));
  }, [location.pathname]);

  const today = new Date();
  const targetDate = new Date(today.getFullYear(), 6, 27);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
        {userInfo.accountStatus != 0 &&
          userInfo.accountId != 11 &&
          userInfo.accountId != 84 &&
          (userInfo.trial.trial_notice || userInfo.trial.advertise) && (
            <NoticeDialog />
          )}
        {/* {isLoggedIn && <StickyButton />} */}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
