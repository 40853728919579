/* eslint-disable*/
//ヘッダー
import { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useLocation, useNavigate } from 'react-router-dom';
import { userinfo, fetchLogout } from 'feactures/user/userSlice';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Popover,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Badge,
  Divider,
  colors
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@material-ui/icons/Menu';
import PaymentIcon from '@mui/icons-material/Payment';
import SettingsIcon from '@mui/icons-material/Settings';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import CachedIcon from '@mui/icons-material/Cached';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Notifications from '@material-ui/icons/Notifications';
import { Circle } from '@material-ui/icons';
import Logo from '../../../Logo';
import MenuNavbar from 'components/organisms/layout/dashboard/MenuNavbar';
import MenuNavbar2 from 'components/organisms/layout/dashboard/MenuNavbar2';
import NestedMenu from 'components/organisms/layout/dashboard/NestedMenu';
import PlanConfirmModal from 'components/pages/market/PlanConfirmModal';
import PlanChangeModal from 'components/pages/market/PlanChangeModal';
import { openConfirmModal, openChangeModal } from 'feactures/plan/planSlice';
import { changeSideMenuOpen, sideMenuProps } from 'feactures/section/pageSlice';
import {
  allNews,
  fetchNews,
  allReadedNews,
  readedNews
} from 'feactures/user/newsSlice';
import { currentPage, currentPageFlag } from 'feactures/date/dateSlice';
// リロードに必要
import { ReloadLayoutContext } from 'components/templates/dashboard/DashboardLayout';
import { currentchannel } from 'feactures/channel/channelSlice';

const DashboardHeader = () => {
  const { reloadStatus, setReloadStatus } = useContext(ReloadLayoutContext);
  const user = useSelector(userinfo);
  const allNewsInfo = useSelector(allNews);
  const sideMenuOpen = useSelector(sideMenuProps);
  const nonReaded = allNewsInfo.filter((item) => item['readedStatus'] === 0); //未読のnewsList
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuId = 'primary-search-account-menu';
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifiAnchorEl, setNotifiAnchorEl] = useState(null);
  const pageFlag = useSelector(currentPageFlag);
  const location = useLocation();
  const path = location.pathname;
  const isMenuOpen = Boolean(anchorEl);
  const notificationOpen = Boolean(notifiAnchorEl);
  const kpiFlag = useSelector(currentPageFlag);
  const channel = useSelector(currentchannel);

  // 時刻部分を00:00:00にリセットする関数
  const resetTime = (date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate());

  // 日本語形式で日付をフォーマットする関数
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month}月${day}日`;
  };

  const today = resetTime(new Date());

  let trialFlag = false;
  let endDate = '';
  let remainingDays = '';

  if (channel == 1 && user.trial.Amazon.trial) {
    trialFlag = true;
    endDate = new Date(user.trial.Amazon.end_date);
    remainingDays = Math.ceil((endDate - today) / (1000 * 60 * 60 * 24));
  } else if (channel == 2 && user.trial.楽天.trial) {
    trialFlag = true;
    endDate = new Date(user.trial.楽天.end_date);
    remainingDays = Math.ceil((endDate - today) / (1000 * 60 * 60 * 24));
    if (path != '/app/measureanalysis' && user.rakutenStatus < 7) {
      trialFlag = false;
    } else if (
      path != '/app/measureanalysis' &&
      user.plan.rakuten.plan_id == 5
    ) {
      trialFlag = false;
    }
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const handleChangePassword = () => {
    handleMenuClose();
    navigate('/app/sys/p/edit');
  };
  const handleConnectionStatus = () => {
    handleMenuClose();
    navigate('/app/sys/connection_status');
  };
  const handleRms = () => {
    handleMenuClose();
    navigate('/app/sys/rms');
  };
  const handleYahoo = () => {
    handleMenuClose();
    navigate('/app/sys/yahoo');
  };
  const handleUser = () => {
    handleMenuClose();
    navigate('/app/sys/user');
  };
  const handlePlan = () => {
    handleMenuClose();
    navigate('/app/sys/plan');
  };
  const handleBilling = () => {
    handleMenuClose();
    navigate('/app/sys/billing');
  };
  const handleAdvertise = () => {
    handleMenuClose();
    navigate('/app/sys/ad');
  };
  const handleLogout = async () => {
    handleMenuClose();
    await dispatch(fetchLogout())
      .then(unwrapResult) //unwrapResult で,dispatch結果がfulfilledかrejectedかで条件分岐できる
      .then(() => {
        setTimeout(function () {
          navigate('/login');
        }, 2000);
      });
    navigate('/login', { replace: false });
  };
  const handleNotificationMenuOpen = (event) => {
    dispatch(fetchNews());
    setNotifiAnchorEl(event.currentTarget);
  };
  const handleNotificationClose = () => {
    setNotifiAnchorEl(null);
  };
  const handleClickNews = (news) => () => {
    // console.log(news.id);
    navigate(`/app/news/${news.id}`);
    handleNotificationClose();
    //未読の場合接続
    if (news.readedStatus === 0) {
      dispatch(readedNews({ newsId: news.id }));
    }
  };
  const handleAllReadedClick = () => {
    dispatch(allReadedNews());
  };
  const handleHelpClick = (event) => {
    let url = 'https://www.oxcim.net/manual';
    window.open(url, '_blank');
  };

  const handleSideMenuOpen = () => {
    dispatch(changeSideMenuOpen());
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleUser}>ユーザー一覧</MenuItem> */}
      {/* <MenuItem onClick={handlePlan}>プラン管理</MenuItem>
      <MenuItem onClick={handleBilling}>ご請求情報</MenuItem> */}
      {/* <MenuItem onClick={handleAdvertise}>広告連携設定</MenuItem> */}
      {user.accountStatus != 0 && (
        <MenuItem onClick={handleConnectionStatus}>データ接続状況確認</MenuItem>
      )}
      <MenuItem onClick={handleRms}>楽天RMS連携設定</MenuItem>
      <MenuItem onClick={handleYahoo} disabled={true}>
        Yahoo!API連携設定
      </MenuItem>
      <MenuItem onClick={handleChangePassword}>パスワード変更</MenuItem>
      <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
    </Menu>
  );

  const style = {
    // width: '100%',
    // // maxWidth: 450,
    width: 360,
    bgcolor: 'background.paper'
  };
  const notification = (
    <Popover
      open={notificationOpen}
      anchorEl={notifiAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      onClose={handleNotificationClose}
    >
      <Box p={1} pb={0} display="flex" alignItems={'center'}>
        <Typography fontWeight="bold" variant="h5">
          お知らせ
        </Typography>
        <Button variant="text" onClick={handleAllReadedClick}>
          全て既読
        </Button>
        <IconButton
          size="small"
          onClick={handleNotificationClose}
          sx={{ marginLeft: 'auto' }}
        >
          <ClearIcon color="action" size="3px" />
        </IconButton>
      </Box>
      <Divider />
      <Box maxHeight="calc(40vh - 10px)" overflow="auto">
        <List sx={style} component="nav" aria-label="mailbox folders">
          {allNewsInfo.length !== 0 ? (
            <>
              {allNewsInfo.map((news) => (
                <ListItem
                  sx={{ fontSize: '12px' }}
                  button
                  divider
                  key={news.id}
                  onClick={handleClickNews(news)}
                >
                  {!news.readedStatus && (
                    // <ListItemIcon>
                    <Circle
                      sx={{ color: colors.red[900], mx: 1 }}
                      fontSize="2px"
                    />
                    // </ListItemIcon>
                  )}
                  <ListItemText
                    sx={{
                      wordBreak: 'break-all',
                      maxHeight: 60
                    }}
                    primary={news.title}
                    secondary={news.startDate}
                  />
                </ListItem>
              ))}
            </>
          ) : (
            <ListItem>
              <ListItemText primary="新着のお知らせはありません" />
            </ListItem>
          )}
        </List>
      </Box>
    </Popover>
  );

  const handleReload = () => {
    // console.log('リロードボタン押下');
    setReloadStatus(true);
  };

  return (
    <>
      <Box
        position="fixed"
        zIndex={10}
        width="100%"
        height="45px"
        display="flex"
        alignItems="center"
        gap={1}
        pl={1}
        bgcolor={
          user.trial.trial_notice && !kpiFlag && trialFlag
            ? '#FFE4DC'
            : '#FFF8E5'
        }
      >
        {user.userId ? (
          <Box display="flex" alignItems="end" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <IconButton onClick={handleSideMenuOpen}>
                <KeyboardDoubleArrowLeftIcon
                  sx={{
                    color: '#000000',
                    transition: 'transform 0.3s',
                    transform: !sideMenuOpen ? 'rotate(180deg)' : 'none'
                  }}
                />
              </IconButton>
              {/* <IconButton
                size="small"
                // edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
              >
                <SettingsIcon sx={{ color: '#000000' }} />
              </IconButton> */}
              <IconButton
                size="small"
                aria-label="show new notifications"
                onClick={handleNotificationMenuOpen}
              >
                <Badge badgeContent={nonReaded.length} color="error">
                  <Notifications sx={{ color: '#000000' }} />
                </Badge>
              </IconButton>
              {pageFlag && (
                <IconButton
                  size="small"
                  // edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleReload}
                >
                  <AutorenewIcon sx={{ color: '#000000' }} />
                </IconButton>
              )}
              <IconButton
                size="small"
                aria-label="link help page"
                onClick={handleHelpClick}
              >
                <ContactSupportIcon sx={{ color: '#000000' }} />
              </IconButton>
              {user.trial.trial_notice && !kpiFlag && trialFlag && (
                <>
                  <Typography
                    fontWeight="bold"
                    color="#F54249"
                    fontSize="17px"
                    bgcolor="#FFE4DC"
                    py="3px"
                    px="8px"
                    borderRadius={2}
                  >
                    トライアル期間中
                  </Typography>
                  <Typography
                    fontWeight="bold"
                    color="#F54249"
                    fontSize="17px"
                    bgcolor="#FFE4DC"
                    py="3px"
                    px="8px"
                    borderRadius={2}
                  >
                    あと：{remainingDays}日
                  </Typography>
                  <Typography
                    fontWeight="bold"
                    color="#F54249"
                    fontSize="17px"
                    bgcolor="#FFE4DC"
                    py="3px"
                    px="8px"
                    borderRadius={2}
                  >
                    {formatDate(endDate)} 23:59まで
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      {renderMenu}
      {notification}
    </>
  );
};
export default DashboardHeader;
