/* eslint-disable*/
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core'; // eslint-disable-line
import DatePicker from 'components/atoms/button/DatePicker';
import MeasureAnalysisDatePicker from 'components/atoms/button/MeasureAnalysisDatePicker';
import MeasuresButtongroup from 'components/atoms/group/MeasuresButtongroup';
import { userinfo } from 'feactures/user/userSlice';
import StickyAnnotation from 'components/atoms/annotation/StickyAnnotation';
import MeasureAnalysisProductRegister from 'components/atoms/modal/MeasureAnalysisProductRegister';
import RankingChangeGraph from 'components/organisms/content/measure/RankingChangeGraph';
import MeasureAnalysisTable from 'components/organisms/content/measure/MeasureAnalysisTable';
import { currentchannel } from 'feactures/channel/channelSlice';

const MeasureAnalysis = ({ premiumStatus }) => {
  const userInfo = useSelector(userinfo);
  const channel = useSelector(currentchannel);
  const [term, setTerm] = useState([]);
  const ch_dict = { 0: 'Amazon', 1: 'Amazon', 2: '楽天', 3: 'Yahoo' };

  return (
    <>
      {userInfo.accountStatus == 0 && <ConnectionDialog />}
      <Box
        sx={{
          backgroundColor: '#FFF8E5',
          height: 'auto',
          minHeight: '100vh',
          width: '100%',
          pb: '80px'
        }}
      >
        <Container maxWidth={false} sx={{ width: '98%', p: 0 }}>
          <Box
            position="sticky"
            top="42px"
            bgcolor="#FFF8E5"
            zIndex={3}
            pt="2px"
            height="auto"
          >
            {/* <StickyAnnotation /> */}
            <Box display="flex">
              <Typography fontSize="16px" sx={{ py: '4px' }}>
                マーケットビュー＞
              </Typography>
              <Typography fontSize="16px" fontWeight="bold" sx={{ py: '4px' }}>
                競合施策分析
              </Typography>
            </Box>
          </Box>
          <RankingChangeGraph premiumStatus={premiumStatus} />
          <Box
            mt={3}
            mb={3}
            p={2}
            sx={{
              backgroundColor: '#F0F4F8',
              borderRadius: '10px'
            }}
          >
            <Box display="flex" justifyContent={'space-between'}>
              <Box display="flex" alignItems={'center'}>
                <Box mr={3}>
                  {/* <Typography fontSize="14px">モール</Typography> */}
                  <MeasuresButtongroup
                    amazonOnly={true}
                    page="measureAnalysis"
                  />
                </Box>
                <Box mr={3}>
                  {/* <Typography fontSize="14px">期間</Typography> */}
                  {/* <DatePicker page="measureAnalysis" setTerm={setTerm} /> */}
                  <MeasureAnalysisDatePicker
                    page="measureAnalysis"
                    setTerm={setTerm}
                  />
                </Box>
              </Box>
              <Box>
                <MeasureAnalysisProductRegister premiumStatus={premiumStatus} />
              </Box>
            </Box>
          </Box>
          <MeasureAnalysisTable premiumStatus={premiumStatus} />
        </Container>
      </Box>
      {/* )} */}
    </>
  );
};

export default MeasureAnalysis;
