/* eslint-disable*/
import * as React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  currentmonth,
  amazonCurrentlastmonth,
  rakutenCurrentlastmonth,
  amazonMonthList,
  rakutenMonthList,
  resetKubunYearMonth,
  changeYearMonth
} from 'feactures/section/sectionSlice';
import * as moment from 'moment/moment';
import 'moment/locale/ja';
import theme from 'theme';
import { makeStyles } from '@material-ui/styles';
import { currentchannel } from 'feactures/channel/channelSlice';
import {
  fetchRankingProductList,
  resetRankingProductPage,
  changeRankingProductDate,
  rankingProductIsLoading,
  rankingProductCategory,
  rankingProductPageCount
} from 'feactures/measure/newMeasureSlice';

const useStyles = makeStyles({
  customSelect: {
    color: '#575757',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: '#E6E6E6'
    }
  },
  formControl: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[400],
        borderWidth: 1
      }
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400]
    }
  }
});

const dispatchTable = {
  rankingProduct: [fetchRankingProductList()]
};

const RankingProductPullDown = (props) => {
  const dispatch = useDispatch();
  const { page } = props;
  const selectMonthDate = useSelector(currentmonth);
  const channel = useSelector(currentchannel);
  const amazonLastmonth = useSelector(amazonCurrentlastmonth);
  const rakutenLastmonth = useSelector(rakutenCurrentlastmonth);
  const amazonList = useSelector(amazonMonthList);
  const rakutenList = useSelector(rakutenMonthList);
  const Loading = useSelector(rankingProductIsLoading);
  const category = useSelector(rankingProductCategory);
  const pageCount = useSelector(rankingProductPageCount);

  let selectMonth = []; //選択年月リスト
  let start = moment('2023-03');
  let lastmonth = amazonLastmonth;
  if (channel == 1) {
    selectMonth = amazonList;
  } else if (channel == 2) {
    start = moment('2023-08');
    lastmonth = rakutenLastmonth;
    selectMonth = rakutenList;
  }

  useEffect(() => {
    dispatch(resetKubunYearMonth());
  }, []);

  useEffect(() => {
    const latestDate = moment(selectMonthDate)
      .startOf('month')
      .format('YYYY-MM-DD');
    dispatch(changeRankingProductDate(latestDate));
  }, [selectMonthDate]);

  // ページネーションの値が変わったらランキング商品を再取得
  useEffect(() => {
    // カテゴリ選択済みの場合のみランキング商品を取得する
    if (category.length > 0 && category[0].categoryId !== '') {
      dispatch(fetchRankingProductList());
    }
  }, [pageCount]);

  const handleChange = (e) => {
    dispatch(changeYearMonth(e.target.value));
    const selectedDate = moment(e.target.value)
      .startOf('month')
      .format('YYYY-MM-DD');
    dispatch(resetRankingProductPage());
    dispatch(changeRankingProductDate(selectedDate));
    // カテゴリ選択済みの場合のみランキング商品を取得する
    if (category.length > 0 && category[0].categoryId !== '') {
      dispatch(fetchRankingProductList());
    }
  };

  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <Select
        className={classes.customSelect}
        value={selectMonthDate}
        onChange={handleChange}
        disabled={Loading}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
          margin: 0,
          padding: 0,
          width: 110,
          height: 30,
          backgroundColor: '#FFFFFF',
          fontSize: '14px'
        }}
      >
        {selectMonth.map((yearMonth, index) => {
          return (
            <MenuItem value={yearMonth} key={index}>
              {yearMonth}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default RankingProductPullDown;
