/* eslint-disable */
import { useEffect, useState, useRef } from 'react';
import { Button, CssBaseline, ButtonGroup } from '@material-ui/core';
import {
  registerAmazon,
  registerRakuten,
  registerYahoo,
  currentchannel
} from 'feactures/channel/channelSlice';
import { useSelector, useDispatch } from 'react-redux';

const MeasuresButtongroup = (props) => {
  const { amazonOnly, page } = props; //eslint-disable-line
  const [amazon, setAmazon] = useState(false);
  const [rakuten, setRakuten] = useState(false);
  const [yahoo, setYahoo] = useState(false);
  const [color1, setColor1] = useState('groupNoselected');
  const [color2, setColor2] = useState('groupNoselected');
  const [color3, setColor3] = useState('groupNoselected');
  const channel = useSelector(currentchannel);
  const dispatch = useDispatch();

  const dispatchTable = {
    measureAnalysis: []
  };

  const [searchByUserFirstNamePromise, setSearchByUserFirstNamePromise] =
    useState();

  //1つ前のリクエストをキャンセルしている
  const dispatchHandle = (currentPage) => {
    //dispatchTableの数だけキャンセル処理を回す
    if (searchByUserFirstNamePromise) {
      if (
        searchByUserFirstNamePromise &&
        typeof searchByUserFirstNamePromise.abort === 'function'
      ) {
        searchByUserFirstNamePromise.abort();
      }
    }
    if (dispatchTable[currentPage].length != 0) {
      //dispatchTableのreducerの数だけdispatchさせる
      [...dispatchTable[currentPage]].map((r, index) => {
        const promise = dispatch(r);
        setSearchByUserFirstNamePromise(promise);
      });
    }
  };

  const changeHandle = (e) => {
    if (e == 1) {
      changeAmazon();
      dispatch(registerAmazon());
    } else if (e == 2) {
      changeRakuten();
      dispatch(registerRakuten());
    } else if (e == 3) {
      changeYahoo();
      dispatch(registerYahoo());
    }
  };

  // チャンネルが変わったら発動 初回レンダリングさせない
  const useEffectCustom = (func, dependencyList) => {
    const fisrtFlgRef = useRef(true);
    useEffect(() => {
      // changeHandle(2); // 初期表示を楽天にする
      if (channel == 0 || channel == 1) {
        changeHandle(1);
      } else if (channel == 2) {
        changeHandle(2);
      } else if (channel == 3) {
        changeHandle(3);
      }

      if (!fisrtFlgRef.current) {
        func();
      } else {
        fisrtFlgRef.current = false;
      }
    }, dependencyList);
  };

  useEffectCustom(() => {
    if (page) {
      window.scrollTo(0, 0);
      dispatchHandle(page);
    }
  }, [channel]);

  const changeAmazon = () => {
    if (amazon) {
      return;
    } else {
      setAmazon(true);
      setRakuten(false);
      setYahoo(false);
      setColor1('groupSelected');
      setColor2('groupNoselected');
      setColor3('groupNoselected');
    }
  };
  const changeRakuten = () => {
    if (rakuten) {
      return;
    } else {
      setAmazon(false);
      setRakuten(true);
      setYahoo(false);
      setColor1('groupNoselected');
      setColor2('groupSelected');
      setColor3('groupNoselected');
    }
  };
  const changeYahoo = () => {
    if (yahoo) {
      return;
    } else {
      setAmazon(false);
      setRakuten(false);
      setYahoo(true);
      setColor1('groupNoselected');
      setColor2('groupNoselected');
      setColor3('groupSelected');
    }
  };

  return (
    <>
      <CssBaseline />
      <ButtonGroup color="inherit" sx={{ borderColor: '#BDBDBD' }}>
        <Button
          onClick={() => {
            changeAmazon(), dispatch(registerAmazon());
          }}
          variant="contained"
          color={color1}
          sx={{
            width: '80px',
            height: '30px',
            borderRadius: 1.5,
            border: 1,
            fontWeight: 'bold',
            borderColor: '#BDBDBD',
            boxShadow: 0
          }}
          value={1}
          disabled={channel !== 1 && !page}
        >
          Amazon
        </Button>
        <Button
          onClick={() => {
            changeRakuten(), dispatch(registerRakuten());
          }}
          value={2}
          variant="contained"
          color={color2}
          sx={{
            width: '80px',
            height: '30px',
            borderRadius: 1.5,
            border: 1,
            fontWeight: 'bold',
            borderColor: '#BDBDBD',
            boxShadow: 0
          }}
          disabled={channel !== 2 && !page}
        >
          楽天
        </Button>
        <Button
          disabled={true}
          onClick={() => {
            changeYahoo(), dispatch(registerYahoo());
          }}
          value={3}
          variant="contained"
          color={color3}
          sx={{
            width: '80px',
            height: '30px',
            borderRadius: 1.5,
            border: 1,
            fontWeight: 'bold',
            borderColor: '#BDBDBD',
            boxShadow: 0
          }}
        >
          ヤフー
        </Button>
      </ButtonGroup>
    </>
  );
};
export default MeasuresButtongroup;
