/* eslint-disable */
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'components/templates/dashboard/DashboardLayout';
import MainLayout from 'components/templates/main/MainLayout';
import Login from 'pages/Login';
import NotFound from 'pages/NotFound';
import Register from 'pages/Register';
import ReregisterPass from 'pages/ReregisterPass';
import ChangePassword from 'pages/ChangePassword';
import SettingUser from 'pages/SettingUser.js';
import UserList from 'pages/UserList';
import AddNewUser from 'pages/AddNewUser';
import SettingAdvertise from 'pages/SettingAdvertise.js';
import ConnectionStatus from 'pages/ConnectionStatus';
import SettingRms from 'pages/SettingRms';
import SettingYahoo from 'pages/SettingYahoo';
import Settings from 'pages/Settings';
import Monitoringboard from 'components/pages/processKPI/Monitoringboard';
import { Monitoringboard2 } from 'components/pages/processKPI/Monitoringboard2';
import KpiSummaryBoard from 'components/pages/kpi_view/KpiSummaryBoard';
import EditGrouping from 'components/pages/processKPI/EditGrouping';
import News from 'pages/News';
import RegisterComplete from 'pages/RegisterComplete';

import { currentchannel } from 'feactures/channel/channelSlice';
import { userinfo } from 'feactures/user/userSlice';

//market
import Categoryboard from 'components/pages/market/Categoryboard';
import PointCategoryboard from 'components/pages/market/PointCategoryboard';
import Productboard from 'components/pages/market/Productboard';
import KeywordsDetail from 'components/pages/processKPI/KeywordsDetail';
import OwnProductboard from 'components/pages/market/OwnProductboard';
import OtherProductboard from 'components/pages/market/OtherProductboard';
import ProductAnalysis from 'components/pages/market/ProductAnalysis';
import Roiboard from 'components/pages/market/Roiboard';
import KeywordVolumeboard from 'components/pages/market/KeywordVolumeboard';
import Category1board from 'components/pages/market/Category1board';
import NewCategoryboard from 'components/pages/market/NewCategoryboard';

//kpi
// import ProcessKPI from './components/pages/processKPI/ProcessKPI';
import AsinPage from 'components/pages/processKPI/AsinPage';
import CVR from 'components/pages/processKPI/CVR';
import NumberViews from 'components/pages/processKPI/NumberViews';
import Advertiseboard from 'components/pages/processKPI/Advertiseboard';
import UnitPrice from 'components/pages/processKPI/UnitPrice';
import Example from 'components/pages/UploadRakutenCSV';
import TermsOfService from 'components/pages/TermsOfService';
import TrendsPage from 'components/pages/processKPI/TrendsPage';
import MarketStatusDialog from 'components/atoms/modal/MarketStatusDialog';
import EditAsinList from 'components/pages/processKPI/EditAsinList';
import SalesByAsin from 'components/pages/processKPI/SalesByAsin';
import BrandAnalysis from 'components/pages/market/BrandAnalysis';
import MeasureAnalysis from 'components/pages/market/MeasureAnalysis';
import NewMeasureAnalysis from 'components/pages/market/NewMeasureAnalysis';
import BrandComparison from 'components/pages/market/BrandComparison';
import ProductMeasures from 'components/pages/market/ProductMeasures';
import TestLogin from 'pages/TestLogin';
import UpdatePassword from 'pages/UpdatePassword';
import SettingPlan from 'pages/SettingPlan';
import CheckoutComplete from 'pages/CheckoutComplete';
import TotalSales from 'components/pages/overview/TotalSales';
import TopPage from 'pages/TopPage';
import PremiumRegister from 'pages/PremiumRegister';
import PremiumRegisterComplete from 'pages/PremiumRegisterComplete';
import Billing from 'pages/Billing';
import PremiumStatusDialog from 'components/atoms/modal/PremiumStatusDialog';
import TrialRegister from 'pages/TrialRegister';
import TrialRegisterComplete from 'pages/TrialRegisterComplete';
import TrialCampaignRegisterComplete from 'pages/TrialCampaignRegisterComplete';
import NewTrialPremiumRegisterComplete from 'pages/NewTrialRegisterComplete';
import TrialPremiumRegister from 'pages/TrialPremiumRegister';
import StepRegister from 'pages/StepRegister';
import NewTrialPremiumRegister from 'pages/NewTrialPremiumRegister';
import MeasuresTrialRegister from 'pages/MeasuresTrialRegister';
import MeasuresTrialRegisterComplete from 'pages/MeasuresTrialRegisterComplete';

//stateのloginがfalseのときはlogin画面に遷移
const routes = (
  isLoggedIn,
  premiumStatus,
  preTrialStatus,
  groupLength,
  channel
) => [
  // ログイン情報関係なく利用規約はみせる
  {
    path: 's',
    element: isLoggedIn ? <DashboardLayout /> : <MainLayout />,
    children: [{ path: 'tos', element: <TermsOfService /> }]
  },
  {
    path: 'checkout',
    element: <CheckoutComplete />
  },
  {
    path: 'app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      //overview
      { path: 'overview', element: <TotalSales /> },

      //dashboard-monitoring
      // { path: 'monitoring', element: <Monitoringboard /> },
      // { path: 'monitoring', element: <Monitoringboard2 /> },
      {
        path: 'monitoring',
        element: <KpiSummaryBoard />,
        children: [
          groupLength > 0 && { path: '/group', element: <KpiSummaryBoard /> },
          { path: '/asin', element: <KpiSummaryBoard /> }
        ]
      },
      // { path: 'group', element: <EditGrouping /> },
      // { path: 'monitoring/asin', element: <EditAsinList /> },

      //kpi
      // { path: 'processkpi', element: <ProcessKPI /> },
      // { path: 'numberviews', element: <NumberViews /> },
      // { path: 'sales', element: <SalesByAsin /> },
      // { path: 'trends', element: <TrendsPage /> },
      // { path: 'advertise', element: <Advertiseboard /> },
      // { path: 'cvr', element: <CVR /> },
      // { path: 'unitprice', element: <UnitPrice /> },
      // { path: 'asin', element: <AsinPage /> },
      // { path: 'keywords/detail', element: <KeywordsDetail /> },

      //market
      // {
      //   path: 'ownproductboard',
      //   element: premiumStatus ? <OwnProductboard /> : <PremiumStatusDialog />
      // },
      {
        path: 'otherproductboard',
        element: premiumStatus ? <ProductAnalysis /> : <PremiumStatusDialog />
      },
      // {
      //   path: 'oldotherproductboard',
      //   element: premiumStatus ? <OtherProductboard /> : <PremiumStatusDialog />
      // },
      // {
      //   path: 'categoryboard',
      //   element: premiumStatus ? (
      //     <PointCategoryboard />
      //   ) : (
      //     <PremiumStatusDialog />
      //   )
      // },
      {
        path: 'newcategoryboard',
        element: premiumStatus ? <NewCategoryboard /> : <PremiumStatusDialog />
      },
      {
        path: 'brandanalysis',
        element: premiumStatus ? <BrandAnalysis /> : <PremiumStatusDialog />
      },
      {
        path: 'brandcomparison',
        element: premiumStatus ? <BrandComparison /> : <PremiumStatusDialog />
      },
      {
        path: 'measureanalysis',
        element: (
          <NewMeasureAnalysis premiumStatus={premiumStatus || preTrialStatus} />
        )
      },
      // {
      //   path: 'productmeasures',
      //   element: premiumStatus ? <ProductMeasures /> : <PremiumStatusDialog />
      // },
      // {
      //   path: 'category1/sales',
      //   element: premiumStatus ? <Category1board /> : <PremiumStatusDialog />
      // },

      //etc
      { path: 'news/:id', element: <News /> },
      { path: 'sys/p/edit', element: <ChangePassword /> },
      { path: 'sys/user', element: <UserList /> },
      { path: 'sys/user/new', element: <AddNewUser /> },
      { path: 'sys/connection_status', element: <ConnectionStatus /> },
      { path: 'sys/rms', element: <SettingRms /> },
      { path: 'sys/yahoo', element: <SettingYahoo /> },
      { path: 'sys/ad', element: <SettingAdvertise /> },
      { path: 'sys/plan', element: <SettingPlan /> },
      { path: 'sys/billing', element: <Billing /> },
      { path: 'tos', element: <TermsOfService /> },
      { path: 'example', element: <Example /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },

  {
    path: '/',
    element: <MainLayout />,
    children: [
      // { path: 'top', element: <TopPage /> },
      { path: 'login', element: <Login /> },
      // { path: 'login', element: <TestLogin /> },
      { path: 's/tos', element: <TermsOfService /> },
      // { path: 'register', element: <Register /> },
      { path: 'register', element: <StepRegister /> },
      { path: 'register/complete', element: <RegisterComplete /> },
      { path: 'premium_register', element: <PremiumRegister /> },
      {
        path: 'trial_premium_register/prev',
        element: <TrialPremiumRegister />
      },
      {
        path: 'new_trial_premium_register',
        element: <NewTrialPremiumRegister />
      },
      {
        path: 'premiumregister/complete',
        element: <PremiumRegisterComplete />
      },
      {
        path: 'trial_campaign_register/complete',
        element: <NewTrialPremiumRegisterComplete />
      },
      {
        path: 'trial_campaign_register/prev/complete',
        element: <TrialCampaignRegisterComplete />
      },
      { path: 'trial_register/prev', element: <TrialRegister /> },
      {
        path: 'trialregister/complete',
        element: <TrialRegisterComplete />
      },
      { path: 'measures_trial_register', element: <MeasuresTrialRegister /> },
      {
        path: 'measurestrialregister/complete',
        element: <MeasuresTrialRegisterComplete />
      },
      { path: 'reregister', element: <ReregisterPass /> },
      { path: 'updatepass', element: <UpdatePassword /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/monitoring" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
